import { render, staticRenderFns } from "./OrderManagementSetting.vue?vue&type=template&id=65c40a07&scoped=true&lang=pug"
import script from "./OrderManagementSetting.vue?vue&type=script&lang=js"
export * from "./OrderManagementSetting.vue?vue&type=script&lang=js"
import style0 from "./OrderManagementSetting.vue?vue&type=style&index=0&id=65c40a07&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65c40a07",
  null
  
)

export default component.exports