<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    //- .col-lg-12.mb-2.title-box
    //- b-button-group.tab-group
    //-   b-button(variant="primary") 編輯稿件
    .col-lg-12.mb-2.title-box
      b-button(variant="secondary" to="/ordermanagement") 回上一層
    .col-lg-12(v-if="order.id")
      b-card.mb-1(no-body)
        b-card-body
          form.form-horizontal(role="form")
            .row
              .col-lg-4
                .form-group.mb-3
                  label 贊助時間
                  br
                  input.form-control(:value="order.time", type="text", disabled)
              .col-lg-4
                .form-group.mb-3
                  label 訂單編號
                  br
                  input.form-control(:value="order.number", type="text", disabled)
              .col-lg-4
                .form-group.mb-3
                  label 贊助種類
                  br
                  select.form-control(v-model="order.source",disabled)
                    option(value="1") 影音聊天室
                    option(value="2") 贊助本文章
                    option(value="3") 小編好棒棒
                    option(value="4") 中天加油

              .col-lg-4
                .form-group.mb-3
                  label 姓名
                  br
                  input.form-control(:value="order.member.user_name", type="text", disabled)
              .col-lg-4
                .form-group.mb-3
                  label 手機號碼
                  br
                  input.form-control(:value="order.member.user_phone", type="text", disabled)
              .col-lg-4
                .form-group.mb-3
                  label email
                  br
                  input.form-control(:value="order.member.user_email", type="text", disabled)
              .col-lg-4
                .form-group.mb-3
                  label 贊助金額
                  br
                  input.form-control(:value="order.amount", type="text", disabled)
              .col-lg-4
                .form-group.mb-3
                  label 訂單狀態 
                    small (訂單退款僅限綠界信用卡)
                  br
                  .row
                    .col.mr-0.pr-0
                      select.form-control(v-model="order.status", disabled)
                        option(value="0") 尚未付款
                        option(value="1") 成功付款
                        option(value="2") 付款失敗
                        option(value="3") 退款成功
                        option(value="4") 退款失敗
                    .col-auto.ml-0
                      //- 僅限綠界付款可執行退款作業
                      b-button.width-sm(
                        v-if="order.status == 1 && order.payment_source !== 2", 
                        variant="primary"
                        @click="returnBillPayment"
                      ) 退款
              .col-lg-4
                .form-group.mb-3
                  label 付款方式
                  br
                  select.form-control(:value="order.payment_source", disabled)
                    option(value="0") 綠界信用卡
                    option(value="1") 綠界信用卡
                    option(value="2") Apple Pay
              .col-lg-4
                .form-group.mb-3
                  label 訂單最後修改日期
                  br
                  input.form-control(:value="order.updated_at", type="text", disabled)

      b-card.mb-1(no-body,v-if="order.source == 1")
        b-card-body
          form.form-horizontal(role="form")
            .row
              .col-lg-4
                .form-group.mb-3
                  label YT影音ID
                  br
                  input.form-control(:value="order.target.yt_videoId",type="text",disabled)
            .row
              .col-lg-12
                .form-group.mb-3
                  label 影音標題
                  br
                  textarea.form-control(:value="order.target.title",rows="3",disabled)

      b-card.mb-1(no-body,v-if="order.source == 2 || order.source == 3")
        b-card-body
          form.form-horizontal(role="form")
            .row
              .col-lg-4
                .form-group.mb-3
                  label 文章編號
                  br
                  input.form-control(:value="order.target.code",type="text",disabled)
              .col-lg-4
                .form-group.mb-3
                  label 作者
                  br
                  input.form-control(:value="order.target.editor.name",type="text",disabled)
            .row
              .col-lg-12
                .form-group.mb-3
                  label 文章標題
                  br
                  textarea.form-control(:value="order.target.title",type="text",rows="3",disabled)

      b-card.mb-1(no-body)
        b-card-body
          form.form-horizontal(role="form")

            .row
              .col-lg-7
                .form-group.mb-3
                  label 留言內容
                  br
                  textarea.form-control(:value="order.comment", rows="4", disabled)
              .col
                label.form-label-row.mt-4
                  b-form-checkbox(
                    v-model="order.is_block",
                    name="checkbox",
                    value="accepted",
                    unchecked-value="not_accepted"
                  ) 是否不雅，核選表示該留言有不雅詞彙
            .row.align-items-end
              .col-lg-7
                .form-group
                  label 客服備註
                  br
                  textarea.form-control(v-model="order.manager_comment", rows="4", placeholder="請輸入備註")
              .col 
                .float-right
                  b-button.width-sm(variant="primary",@click="setBillComment") 儲存

      b-card.mb-1(no-body)
        b-card-body
          b-table(
            :items="order.logs", 
            :fields="orderLogFields", 
            responsive, 
          )
            template(v-slot:cell(type)="row")
              span(v-if="row.item.type == 1") 建立訂單
              span(v-if="row.item.type == 2") 請求前往付款
              span(v-if="row.item.type == 3") 付款成功
              span(v-if="row.item.type == 4") 付款失敗
              span(v-if="row.item.type == 5") 管理員備註
              span(v-if="row.item.type == 6") 管理員請求退款
              span(v-if="row.item.type == 7") 請求前往綁定

            template(v-slot:cell(manager)="row")
              span {{ row.item.manager.name }}
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Select2 from 'v-select2-component';
import SelectImage from "@/components/select-image";

/**
 * Starter component
 */
export default {
  name: "OrderBillDetail",
  data () {
    return {
      orderHashID: "",
      title: "訂單詳情",
      items: [
        {
          text: "訂單管理",
          href: "/ordermanagement",
        },
        {
          text: "訂單詳情",
          active: true,
        }
      ],
      order: false,
      totalRows: 0,
      currentPage: 1,
      perPage: 0,
      orderLogFields: [
        { key: "time", label: "異動日期", sortable: false },
        { key: "type", label: "異動項目", sortable: false },
        { key: "manager", label: "異動人員", sortable: false }
      ]
    };
  },
  computed: {

  },
  mounted () {

  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    Select2,
    SelectImage
  },
  watch: {

  },
  created () {
    this.orderHashID = this.$route.params.id
    this.getOrderDetail()
  },
  methods: {
    // 獲得單一訂單資訊
    getOrderDetail () {
      let vm = this
      this.$root.apis.getBillDetail(this.orderHashID, function (_response) {
        let order = _response.body.data
        order.is_block = (order.is_block) ? 'accepted' : 'not_accepted'
        vm.order = order
      })
    },
    // 更新訂單備註資訊
    setBillComment () {
      let vm = this
      // 確認是否需要設置為不雅留言
      let isBlock = (this.order.is_block === 'accepted') ? true : false
      // 更新訂單資訊
      this.$root.apis.setBillComment(this.orderHashID, {
        is_block: isBlock,
        manager_comment: this.order.manager_comment
      }, function (_response) {
        vm.getOrderDetail()
        vm.$root.common.showSingleNotify("訂單資訊更新成功！")
      }, function (_error) {
        vm.$root.common.showErrorNotify(_error.body.data)
      })
    },
    // 請求進行退款作業
    returnBillPayment () {
      let vm = this
      this.$root.apis.returnBillPayment(
        this.orderHashID,
        function (_response) {
          vm.getOrderDetail()
          vm.$root.common.showSingleNotify("退款操作成功！")
        },
        function (_response) {
          vm.$root.common.showErrorNotify(_response.body.data)
        })
    }
  }
};
</script>

<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)

.form-label-row
  display: flex
  gap: 14px

.add-btn
  width: 100%
  border: dashed 1px
.del-btn
  flex: 1
  display: flex
  align-items: center
  position: relative
  top: 3px
  .btn
    padding: 0rem .3rem
    border-radius: 50px


</style>
